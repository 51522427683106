import profilePic from "./Pictures/profile-pic.png";
import arrowPic from "./arrow.png";
import checkmarkPic from "./checkmark.png";
import educationPic from "./education.png";
import emailPic from "./email.png";
import experiencePic from "./experience.png";
import githubPic from "./github.png";
import linkedinPic from "./linkedin.png";
import aboutPic from "./Pictures/about-pic.jpeg"

const images = {
  aboutPic,
  profilePic,
  arrowPic,
  checkmarkPic,
  educationPic,
  emailPic,
  experiencePic,
  githubPic,
  linkedinPic,
};

export default images;
