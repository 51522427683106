import React from 'react';
import Nav from './Components/Nav'
import Profile from './Components/Profile';
import About from './Components/About';
import Experience from './Components/Experience';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Projects from './Components/Projects';

import './styles/index.css';
import './styles/mediaqueries.css';

// App Component
function App() {
  return (
    <div className="App">
      <Nav />
      <Profile />
      <About />
      <Experience />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
