import React from 'react';
import images from '../Assets';

function About() {
  return (
    <section id="about">
      <p className="section__text__p1">Get To Know More</p>
      <h1 className="title">About Me</h1>
      <div className="section-container">
        <div className="about-pic-container">
          <img
            src={images.aboutPic}
            alt="About Profile"
            className="about-pic"
          />
        </div>
        <div className="about-details-container">
          <div className="about-containers">
            <div className="details-container">
              <img
                src={images.experiencePic}
                alt="Experience icon"
                className="icon"
              />
              <h3>Experience</h3>
              <p>6+ Years<br />Software Development Engineer in Test</p>
            </div>
          </div>
          <div className="text-container">
            <p>
                Hello, I'm Alvin Divina, a seasoned Software Development Engineer in Test (SDET) 
                based in Chicago, Illinois. With 6+ years of experience, 
                I've been at the forefront of ensuring software excellence through robust testing practices
                and innovative automation solutions.
            </p>
          </div>
        </div>
      </div>
      <img
        src={images.arrowPic}
        alt="Arrow icon"
        className="icon arrow"
        onClick={() => { window.location.href = './#experience'; }}
      />
    </section>
  );
}

export default About
