import checkout from "./checkout.png";
import landing from "./landing.png";
import pizza from "./pizza.png";
import purchase from "./purchase.png";

const images = {
  checkout,
  landing,
  pizza,
  purchase,
};

export default images;
