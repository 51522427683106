import React, { useState }from 'react';
import images from '../Assets';
import resumePdf from '../Assets/AlvinDivina.pdf'

// Profile Component
function Profile() {
  const [linkedinUrl] = useState('https://www.linkedin.com/in/alvindivina');
  const [githubUrl] = useState('https://github.com/alvindivina08');

  const handleClick = (url) => {
    window.location.href = url;
  };

  return (
    <section id="profile">
      <div className="section__pic-container">
        <img src={images.profilePic} alt="Profile" />
      </div>
      <div className="section__text">
        <p className="section__text__p1">Hello, I'm</p>
        <h1 className="title">Alvin Divina</h1>
        <p className="section__text__p2">Software Engineer</p>
        <div className="btn-container">
          <button
            className="btn btn-color-2"
            onClick={() => window.open(resumePdf, '_blank')}
          >
            Download CV
          </button>
          <button className="btn btn-color-1" 
            onClick={() => handleClick('./#contact')}
          >
            Contact Info
          </button>
        </div>
        <div id="socials-container">
          <img
            src={images.linkedinPic}
            alt="My LinkedIn profile"
            className="icon"
            onClick={() => handleClick(linkedinUrl)}
          />
          <img
            src={images.githubPic}
            alt="My Github profile"
            className="icon"
            onClick={() => handleClick(githubUrl)}
          />
        </div>
      </div>
    </section>
  );
}

export default Profile
