import React from 'react';
import images from '../Assets';


// Experience Component
function Experience() {
  const experienceData = [
    {
      title: 'Software Development Engineer in Test',
      items: ['JavaScript', 'APIs', 'Java', 'Python', 'Docker', 'Jenkins']
    },
    {
      title: 'Test Frameworks',
      items: ['Selenium', 'Appium', 'WDIO', 'Jest', 'Axios', 'Cucumber', 'Mocha']
    },
    {
      title: 'Full Stack Development',
      items: ['MongoDB', 'React', 'Node', 'NextJS', 'ShadCN',"PayloadCMS", "GraphQL"]
    }
  ];

  return (
    <section id="experience">
      <p className="section__text__p1">Explore My</p>
      <h1 className="title">Experience</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          {experienceData.map((category, index) => (
            <div className="details-container" key={index}>
              <h2 className="experience-sub-title">{category.title}</h2>
              <div className="article-container">
                <div className="article-column">
                  {category.items.slice(0, Math.ceil(category.items.length / 2)).map((item, itemIndex) => (
                    <article key={itemIndex}>
                      <img
                        src={images.checkmarkPic}
                        alt="Experience icon"
                        className="icon"
                      />
                      <div>
                        <h3>{item}</h3>
                      </div>
                    </article>
                  ))}
                </div>
                <div className="article-column">
                  {category.items.slice(Math.ceil(category.items.length / 2)).map((item, itemIndex) => (
                    <article key={itemIndex}>
                      <img
                        src={images.checkmarkPic}
                        alt="Experience icon"
                        className="icon"
                      />
                      <div>
                        <h3>{item}</h3>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img
        src={images.arrowPic}
        alt="Arrow icon"
        className="icon arrow"
        onClick={() => { window.location.href = './#projects'; }}
      />
    </section>
  );
}

export default Experience
