import React, { useEffect, useState, useCallback } from 'react';
import './carousel.css'

function ProjectCarouselImage({images, projectName}) {
    const [slideIndex, setSlideIndex] = useState(1);

    const showSlides = useCallback((n) => {
        let i;
        let slides = document.getElementsByClassName(`mySlides${projectName}`);
        let dots = document.getElementsByClassName(`dot ${projectName}`);
        if (slides.length === 0) return;
        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[n - 1].style.display = "block";
        dots[n - 1].className += " active";
    }, [projectName]);

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex, showSlides]);

    function plusSlides(n) {
        const newSlideIndex = slideIndex + n;
        setSlideIndex(newSlideIndex);
        showSlides(newSlideIndex);
    }

    function currentSlide(n) {
        setSlideIndex(n);
        showSlides(n);
    }

    function fullView(ImgLink, index) {
        document.getElementsByClassName("fullImage")[0].src = ImgLink;
        document.getElementsByClassName("fullImageView")[0].style.display = "flex"
        hideElements("project-img", "prev", "next", "numbertext")
        document.getElementById("ArrowIcon").style.display = "none";
        const closeButton = document.getElementsByClassName("closeButton")[0];
        closeButton.onclick = function() {
            closeFullView(index);
        }
    }

    function hideElements(...classNames) {
        classNames.forEach((value) => {
            const elements = document.getElementsByClassName(value);
            Array.from(elements).forEach((value) => {
                value.style.display = "none";
            });
        })
    }

    function showElements(...className) {
        className.forEach((value) => {
            const elements = document.getElementsByClassName(value);
            Array.from(elements).forEach((value) => {
                value.style.display = "block";
            });
        })
    }

    function closeFullView(index) {
        document.getElementsByClassName("fullImageView")[0].style.display = "none";
        document.getElementsByClassName("slideshow-container")[0].style.display = "block";
        document.getElementsByClassName(`mySlides${projectName}`)[index].style.display = "block";
        document.getElementById("ArrowIcon").style.display = "block";
        showElements("project-img", "prev", "next", "numbertext")
    }

    const slides = Object.keys(images).map((key, index) => ({
        src: images[key],
        alt: `${key}${index + 1}`,
        numbertext: `${index + 1} / ${Object.keys(images).length}`
    }));

  return (
    <div>
        <div className="fullImageView">
            <img className="fullImage" alt="fullImage" />
            <span className="closeButton">&times;</span>
        </div>
        <div className="slideshow-container">
            {slides.map((slide, index) => (
                <div className={`mySlides${projectName} fade`} key={index} onClick={() => fullView(slide.src, index)}>
                    <div className="numbertext">{slide.numbertext}</div>
                    <img src={slide.src} alt={slide.alt} className={`project-img`} />
                </div>
            ))}
            {/* Navigation arrows */}
            <button  className="prev" onClick={() => plusSlides(-1)}>&#10094;</button >
            <button  className="next" onClick={() => plusSlides(1)}>&#10095;</button >
        </div>
        <div className="text-center">
            {slides.map((_, index) => (
                <span className={`dot ${projectName}`} key={index} onClick={() => currentSlide(index + 1)}></span>
            ))}
        </div>
    </div>
  );
}

export default ProjectCarouselImage;