import React from "react";
import images from "../Assets";
import projImage from "../Assets/Projects/JavaFramework";
import projTwoImage from "../Assets/Projects/ChatBox";
import linkedInProject from "../Assets/Projects/LinkedInAutomation";
import ProjectCarouselImage from "./carousel/Carousel";
import ecommerce from "../Assets/Projects/ecommerce";

function Projects() {
  const handleUrLClick = (url) => {
    window.location.href = url;
  };
  return (
    <section id="projects">
      <p className="section__text__p1">Browse My Recent</p>
      <h1 className="title">Projects</h1>
      <div className="experience-details-container">
        <div className="about-containers">
        <div className="details-container color-container">
            <div className="project-article-container">
              <ProjectCarouselImage
                images={projImage}
                projectName="TestFramework"
              />
            </div>
            <h2 className="experience-sub-title project-title">
              Test Framework using Java
            </h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick(
                    "https://github.com/alvindivina08/Alvin_Divina_Practice_Project"
                  )
                }
              >
                Github
              </button>
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick("https://www.youtube.com/watch?v=yXxCQG9H2bk")
                }
              >
                Live Demo
              </button>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="project-article-container">
              <ProjectCarouselImage
                images={linkedInProject}
                projectName="LinkedIn Automation"
              />
            </div>
            <h2 className="experience-sub-title project-title">LinkedIn Automation</h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick("https://github.com/alvindivina08/LinkedIn-Auto-Uncheck")
                }
              >
                Github
              </button>
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick("https://www.youtube.com/watch?v=AVCjXgovXrs")
                }
              >
                Live Demo
              </button>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="project-article-container">
              <ProjectCarouselImage
                images={ecommerce}
                projectName="Ecommerce"
              />
            </div>
            <h2 className="experience-sub-title project-title">Ecommerce</h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() => handleUrLClick("https://github.com")}
              >
                Github
              </button>
              <button
                className="btn btn-color-2 project-btn"
                onClick={() => handleUrLClick("https://youtu.be/9AIc7PgT0H4")}
              >
                Live Demo
              </button>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="project-article-container">
              <ProjectCarouselImage
                images={projTwoImage}
                projectName="ChatBox"
              />
            </div>
            <h2 className="experience-sub-title project-title">Chat Box</h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick("https://github.com/alvindivina08/DivinaChat")
                }
              >
                Github
              </button>
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  handleUrLClick("https://main.d287pp4h8ic9ud.amplifyapp.com")
                }
              >
                Live Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={images.arrowPic}
        alt="Arrow icon"
        className="icon arrow"
        id="ArrowIcon"
        onClick={() => handleUrLClick("./#contact")}
      />
    </section>
  );
}

export default Projects;
