import React from 'react';
import HamburgerIcon from './HamburgerIcon';

function Nav() {
  return (
    <>
      {/* Desktop Navigation */}
      <nav id="desktop-nav">
        <div className="logo">Portfolio</div>
        <div>
          <ul className="nav-links">
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </nav>

      {/* Hamburger Navigation */}
      <nav id="hamburger-nav">
        <div className="logo">Portfolio</div>
        <div className="hamburger-menu">
          {/* Hamburger Icon can be a separate component */}
          <HamburgerIcon />
          <div className="menu-links">
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
