import javaImageOne from "./JavaImageOne.png"
import javaImageTwo from "./JavaImageTwo.png"
import javaImageThree from "./JavaImageThree.png"

const images = {
  javaImageOne,
  javaImageTwo,
  javaImageThree
};

export default images;
